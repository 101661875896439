


















import Vue from "vue";
export default Vue.extend({
  props: ["post"], // attribute needs tabTitle, selectedTab, tabs[text, link]
  data: () => ({}),
  watch: {
    "post.selectedTab": function (val, oldVal) {
      console.log("switch " + oldVal + " to " + val);
      this.$router.push(this.post.tabs[this.post.selectedTab].path);
    },
  },
});
