















import Vue from "vue";
import TabList from "@/components/TabList.vue";
export default Vue.extend({
  components: { TabList },
  data: () => ({
    tab: {
      selectedTab: 0,
      tabTitle: "成果推介",
      tabs: [
      ],
    },
  }),
});
